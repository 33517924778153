<template>
  <div class="k-9sptci">
    <!-- 查询数据 -->
    <div slot class="clearfix">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="内容">
          <el-input
            placeholder="请输入内容"
            v-model="searchForm.key"
            @keyup.enter.native="handleQuery()"
          />
        </el-form-item>

        <el-form-item label="类型">
          <el-select
            @change="handleDepot()"
            v-model="searchForm.type"
            placeholder="请选择查询条件"
          >
            <el-option label="门派联盟" :value="3"></el-option>
            <el-option label="官方通知" :value="5"></el-option>
          </el-select>
        </el-form-item>

        <el-button
          @click="handleQuery()"
          style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
          type="primary"
          >查 询</el-button
        >
        <el-button
          @click="addUser"
          style="margin-left: 20px; margin-right: 20px;padding: -9px 30px; font-size: 15px"
          type="primary"
          >新建通知</el-button
        >
      </el-form>
    </div>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <el-table-column
        v-for="item in dataList"
        :key="item._id"
        :prop="item.prop"
        :type="item.type"
        :label="item.label"
        :width="item.width"
        :formatter="item.formatter"
      >
      </el-table-column>
      <!-- <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
            >编辑</el-button
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleDelete(scope.row._id, scope.row.status)"
            >{{ scope.row.status == 1 ? "删除" : "恢复" }}</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增通知"
      :visible.sync="userVisible"
      v-if="userVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form ref="userForm" :model="userData" label-width="100px">
        <el-form-item label="标题" prop="title"    :rules="[
            {
              required: true,
              message: '请填写标题',
              trigger: ['blur'],
            },
          ]">
          <el-input
            v-model="userData.title"
            placeholder="请填写标题"
          
          ></el-input>
        </el-form-item>
        <el-form-item label="模块">
          <el-select v-model="userData.type" placeholder="请选择查询条件">
            <el-option label="门派联盟" :value="3"></el-option>
            <el-option label="官方通知" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送的用户ID">
          <el-radio-group
            v-model="userData.save_type"
            @change="select_type(userData.save_type)"
          >
            <el-radio :label="1">狼人杀编号发送</el-radio>
            <el-radio :label="2">全部用户发送</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="成员id" v-if="userData.save_type == 1">
          <el-select
            class="users-style"
            v-model="userData.users"
            filterable
            remote
            multiple
            placeholder="请输入成员id"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in options"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="内容"
          prop="content"
          :rules="[
            {
              required: true,
              message: '请输入内容',
              trigger: ['blur'],
            },
          ]"
        >
          <el-input
            v-model="userData.content"
            placeholder="请输入内容名称"
            type="textarea"
            rows="4"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBanner" :loading="loading"
            >确定</el-button
          >
          <el-button @click="userVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { randomStr } from '@/utils/randomStr'
import { uploadOssUrl } from '@/utils/uploadUrl'
import { MessageBox } from 'element-ui'

export default {
  name: 's1_banners',
  computed: {
    ...mapState('match', ['match_types']),
  },

  data() {
    return {
      content_top:'',
      pager: {
        total: 10,
        pageNum: 1,
      },
      options: [],
      ids: [],
      type_id: 's1',
      match_id: '',
      loading: false,
      searchForm: {
        // status: 1,
        key:''
      },
      dataTable: [],
      dataList: [
        {
          prop: 'id',
          label: 'id',
        },
        {
          prop: 'type',
          label: '模块',

          formatter: (row) => {
            switch (row.type) {
              case 3:
                return '投稿'
              case 5:
                return '官方通知'
              default:
                return ''
            }
          },
        },
        {
          prop: 'by_user_uid',
          label: '狼人杀编号',
        },
        {
          prop: 'content',
          label: '内容',
        },
        {
          prop: 'createTime',
          label: '发送时间',
          formatter: (row) => {
            return new Date(row.createTime).format('yyyy-MM-dd hh:mm:ss')
          },
        },
      ],
      userVisible: false,
      view_type: 'add',
      userData: {
        _id: '',
        type: 3, //模块
        save_type: 2, //编号id
        content: '', //内容
        users: [],
        title: '',
      },
      match: false,
    }
  },
  methods: {
    async remoteMethod(query) {
      if (query !== '' && query.length == 8) {
        let param = {
          uid: query,
        }

        let { data } = await this.$http.operate.notice_user_uid(param)
        if (!data) {
          this.options = []
        } else {
          this.options = [data.lrs]
        }
      }
    },
    select_type(type) {
      if (type == 1) {
        console.log(11)
      } else {
        this.userData.users = []
      }
    },
    // 添加初始化
    addUser() {
      this.view_type = 'add'
      this.userData._id = ''
      this.userData.type = 3
      this.userData.save_type = 2
      this.userData.content = ''
      this.userData.users = []
      this.options = []
      this.userVisible = true
    },
    //编辑赋值
    handleEdit(row) {
      this.view_type = 'edit'
      this.userData._id = row._id
      this.userData.content = row.content
      this.userData.name = row.name
      this.userVisible = true
    },
    //添加banner
    saveBanner() {
      this.$refs.userForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true
          let saveData = {
            content: this.userData.content,
            save_type: this.userData.save_type,
            users: this.userData.users,
            type: this.userData.type,
            title:this.userData.title
          }

          let { data, code } = await this.$http.operate.save_notice(saveData)
          this.loading = false

          if (code == 0) {
            this.$message({
              type: 'success',
              message: '发送成功',
            })

            this.userVisible = false
            this.handleQuery()
          }
        }
      })
    },
    //删除
    async handleDelete(_id, status) {
      let status_title = status == 1 ? '是否删除' : '是否恢复'
      await MessageBox.confirm(status_title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })

      let new_status = status == 0 ? 1 : 0
      const { code } = await this.$http.operate.update_status({
        _id: _id,
        status: new_status,
      })
      if (code === 0) {
        this.$message({
          type: 'success',
          message: '操作成功',
        })
        this.handleQuery()
      }
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1
      this.handleQuery()
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager
console.log(111,this.searchForm);
      //请求接口
      const { data, errorCode } = await this.$http.operate.notice_info({
        ...this.searchForm,
       
        pageNum,
      })

      this.dataTable = data.data
      this.pager = data.pager
    },
    //tab的变化
    ...mapActions('tabs', [
      'handleTabsEdit',
      'addTabs',
      'handleTabsClick',
      'removeTab',
      'removeTabByPath',
    ]),
  },
  async mounted() {
    //查询列表
    this.handleQuery()
  },
  destroyed() {},
}
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
.users-style {
  width: 100%;
}
</style>
